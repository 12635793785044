.row {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and (min-width: 991px){
        flex-direction: row;
        gap: 20px;
    }

    .col {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-50 {
            width: 100%;

            @media screen and (min-width: 991px){
                width: 50%;
            }
        }

        &-25 {
            width: 100%;

            @media screen and (min-width: 991px){
                width: 25%;
            }
        }
    }
}
