html {
    height: 100%;
}

body {
    font-family: var(--base-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

p {
    font-size: 14px;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dashboard {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1201px) {
        flex-direction: row;
    }
}

[x-cloak] {
    display: none !important;
}
