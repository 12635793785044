@font-face {
  font-family: 'Panton';
  src: url('../fonts/paton-bold.otf') format('opentype'),
  url('../fonts/paton-bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Panton';
  src: url('../fonts/paton-extra-bold.otf') format('opentype'),
  url('../fonts/paton-extra-bold.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Panton';
  src: url('../fonts/paton-semi-bold.otf') format('opentype'),
  url('../fonts/paton-semi-bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
