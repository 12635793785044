.styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it


    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;

        display: flex;
        align-items: center;
        color: #000;
    }

    // Box.
    & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid var(--c-primary);
    }

    // Box checked
    &:checked + label:before {
        background: var(--c-primary);
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
        transform: rotate(45deg);
    }
}
