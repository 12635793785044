.alert {
    display: block;
    padding: 30px;
    margin-bottom: 15px;

    &--error {
        background: #e6392a;
        color: #ffebe3;
    }

    &--warning {
        background: #ffb55b;
        color: #8e4010;
    }

    &--info {
        background: #2d78b5;
        color: #cce5fa;
    }

    &--success {
        background: #009f53;
        color: #a2f9cc;
    }

    &--negative-top {
        margin-top: -15px;
    }

    p, li {
        line-height: 1.2;
        font-size: 16px;
        font-weight: 400;
    }

    p {
        margin-bottom: 15px;
    }

    li {
        line-height: 25px;
    }

}
