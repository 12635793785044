.auth {
    &__background {
        position: relative;

        img {
            position: fixed;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__logo {
        position: absolute;
        top: 0;
        z-index: 10;

        @media screen and (min-width: 991px){
            left: 15px;
        }

        @media screen and (min-width: 1600px){
            left: 120px;
        }

        img {
            width: 60px;

            @media screen and (min-width: 991px){
                width: 90px;
            }

            @media screen and (min-width: 1600px){
                width: 110px;
            }
        }
    }

    &__form {
        position: absolute;
        top:70px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media screen and (min-width: 500px){
            top: 105px;
        }

        @media screen and (min-width: 991px){
            justify-content: center;
            top: 15px;
        }

        &-label {
            font-weight: bold;
            display: block;
            margin-bottom: 8px;
        }

        &-input {
            background: #F7F7F7;
            padding: 15px;
            width: 100%;
            border: none;
            margin-bottom: 20px;

            &--error {
                padding-right: 50px;
                border: 1px solid #e6392a;
                background: #F7F7F7 url('../images/icon-alert.svg') no-repeat center right 15px;
                background-size: 21px;

                &:focus,
                &:active {
                    outline: 0;
                    border-color: rgba(#e6392a, .5);
                }
            }
        }

        &-error {
            margin-bottom: 15px;
            margin-top: -15px;
            font-size: 14px;
            color: #e6392a;
        }

        .additional {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
        }

        .note {
            display: inline-block;
        }

        form {
            max-width: 1140px;
            background: white;
            padding: 30px 15px;

            @media screen and (min-width: 500px){
                padding: 30px;
            }

            @media screen and (min-width: 991px){
                padding: 40px;
            }

            @media screen and (min-width: 1600px){
                padding: 70px 40px;
            }
        }

        h1 {
            color: var(--c-primary);
            margin-top: 0;
        }

        h2 {
            color: var(--c-primary);
        }

        .m-auto-mt {
            display: flex;
            margin: 0 auto;
            margin-top: 20px;
        }

        .mb {
            margin-bottom: 30px;
        }
    }
}
