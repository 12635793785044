.btn {
    &__primary {
        cursor: pointer;
        height: 40px;
        padding: 0 15px 20px 15px;
        font-size: 14px;
        font-weight: 700;
        display: inline-block;
        line-height: 38px;
        text-decoration: none;
        border: 2px solid;
        border-radius: 40px;
        color: var(--c-white);
        background-color: var(--c-primary);
        border-color: var(--c-primary);
        transition: ease-in-out .3s;
        white-space: nowrap;

        @media screen and (min-width: 500px) {
            height: 60px;
            padding: 0 35px;
            line-height: 56px;
        }

        &:hover {
            background-color: transparent;
            color: var(--c-primary);
            transition: ease-in-out .3s;
        }
    }

    &__secondary {
        background: #F2F2F3;
        cursor: pointer;
        height: 40px;
        padding: 0 15px 20px 15px;
        font-size: 14px;
        font-weight: 700;
        display: inline-block;
        line-height: 38px;
        text-decoration: none;
        border: 2px solid;
        border-radius: 40px;
        color: var(--c-black);
        border-color: #F2F2F3;
        transition: ease-in-out .3s;
        white-space: nowrap;

        @media screen and (min-width: 500px) {
            height: 60px;
            padding: 0 35px;
            line-height: 56px;
        }

        &:hover {
            background-color: transparent;
            color: var(--c-primary);
            transition: ease-in-out .3s;
        }
    }

    &__link {
        color: var(--c-primary);
        font-weight: 500;
        font-size: 14px;
        transition: ease-in-out .3s;

        &:hover {
            color: var(--c-secondary);
            transition: ease-in-out .3s;
        }
    }

    &__linkblue {
        color: var(--c-secondary);

        &:hover {
            color: var(--c-primary);
            transition: ease-in-out .3s;
        }
    }

    &__fullwidth {
        width: 100%;
    }
}
