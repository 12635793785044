.modal {
    position: fixed;
    z-index: 50;
    background: rgba(0, 0, 0, .6);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px);

    &__inner {
        max-height: 90vh;
        max-width: 95%;
        width: 100%;
        overflow-y: auto;

        @media (min-width: 1024px) {
            max-width: 768px;
        }
    }

    &__content {
        padding: 30px;
        width: 100%;
        position: relative;
        background: #FFF;
        border-radius: 10px;
        max-height: 100%;

        &--extra-padding {
            padding: 60px;

            img {
                margin-bottom: 30px;
            }
        }

        &--centered {
            text-align: center;
        }

        h2 {
            margin-bottom: 20px;
        }

        p {
            font-size: 16px;
            margin-bottom: 15px;
        }

        .add-to-card-modal {
            position: relative;

            &.is-loading {
                &:before {
                    position: absolute;
                    content: '';
                    background: rgba(255, 255, 255, .7) url('../images/loader.svg') no-repeat center center;
                    background-size: 35px;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .add-card-form {
            margin-top: 30px;

            label {
                display: block;
                margin-bottom: 10px;
            }

            input {
                width: 80%;
                border: 1px solid #e6e6e6;
                height: 50px;
                padding: 0 15px;

                &.error {
                    padding-right: 50px;
                    border: 1px solid #e6392a;
                    background: #F7F7F7 url('../images/icon-alert.svg') no-repeat center right 15px;
                    background-size: 21px;

                    &:focus,
                    &:active {
                        outline: 0;
                        border-color: rgba(#e6392a, .5);
                    }
                }
            }

            &__error {
                //margin-bottom: 15px;
                //margin-top: -15px;
                font-size: 14px;
                color: #e6392a;
                margin-top: 5px;
                font-weight: bold;
            }
        }

        .roospas-card-input {
            margin: 10px 0;
            display: flex;
            border-radius: 30px;
            padding: 15px;
            background-color: #f1f1f1;
            text-align: center;
            justify-content: center;

            @media screen and (min-width: 500px) {
                padding: 20px 30px;
                margin: 20px 0;
            }

            @media screen and (min-width: 991px) {
                text-align: initial;
            }

            label {
                display: inline;
                margin-right: 5px;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background: transparent;
        z-index: 51;
        cursor: pointer;

        svg {
            width: 21px;
            height: 21px;
        }

        svg path {
            color: #999;
        }
    }
}
