.sidebar {
    padding: 30px 15px;
    background-color: #F7F7F7;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @media screen and (min-width: 991px){
        padding: 30px;
    }

    @media screen and (min-width: 1201px){
        width: 30%;
        height: 100vh;
    }

    @media screen and (min-width: 1920px){
        width: 25%;
        padding: 50px;
    }

    &__account {
        display: flex;
        flex-direction: row;
        justify-content: center;

        @media screen and (min-width: 1201px){
            justify-content: initial;
        }

        &-avatar {
            display: none;

            @media screen and (min-width: 500px){
                display: flex;
                border-radius: 50%;
                background-color: var(--c-primary);
                width: 44px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                justify-content: center;
            }

            span {
                color: var(--c-white);
                line-height: 44px;
                width: 44px;
                height: 44px;
            }
        }
        &-name {
            @media screen and (min-width: 500px) {
                padding-left: 20px;
            }

                h1 {
                    text-align: center;
                margin: 0;
                font-size: 20px;

                    @media screen and (min-width: 500px) {
                        text-align: left;
                    }

                }

            &-buttons {
                text-align: center;
                margin-top: 5px;
                display: flex;
                align-items: center;
                gap: 15px;

                @media screen and (min-width: 500px) {
                    text-align: left;
                }

                a {
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
        }
    }

    &__roospas {
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        //padding: 15px 0;
        padding: 0 0 15px 0;

        &-actions {
            text-align: center;

            a {
                display: inline-block;
                padding: 15px 20px;
            }
        }

        .swiper-button-prev, .swiper-button-next {
            top: 35%;
            background: var(--c-primary);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: white;
            font-weight: 700;
            border: 2px solid var(--c-primary);
            transition: ease-in-out 0.3s;

            @media screen and (min-width: 500px) {
                top: 25%;
            }

            &:hover {
                background: #F7F7F7;
                transition: ease-in-out 0.3s;
                color: var(--c-primary);
            }

            &:after {
                font-size: 18px;
            }
        }

        .swiper-button-prev {
            &:after {
                margin-left: -3px;
            }
        }

        .swiper-button-next {
            &:after {
                margin-right: -3px;
            }
        }

        &-personal {
            padding: 40px 0 0 0;

            @media screen and (min-width: 500px){
                padding: 40px 0;
            }

            @media screen and (min-width: 1201px){
                padding: inherit;
            }
        }

        &-images {
            position: relative;
            text-align: center;

            @media screen and (min-width: 1201px){
                width: 100%;
            }

            img{
                width: 200px;

                @media screen and (min-width: 991px){
                    width: 250px;
                }

                @media screen and (min-width: 1920px){
                    width: 280px;
                }
            }

            &-type {
                position: absolute;
                right: 0;
                top: -30px;
                width: 60px!important;

                @media screen and (min-width: 991px){
                    right: -30px;
                }

                @media screen and (min-width: 1920px){
                    width: 80px!important;
                }
            }
        }

        &-personal-number {
            margin: 10px 0;
            display: flex;
            border-radius: 30px;
            padding: 15px;
            background-color: #f1f1f1;
            text-align: center;
            justify-content: center;

            @media screen and (min-width: 500px){
                padding: 20px 30px;
                margin: 20px 0;
            }

            @media screen and (min-width: 991px){
                text-align: initial;
            }

            p{
                font-size: 14px;

                strong {
                    margin-right: 5px;
                }
            }
        }

        &-personal-points {
            margin-bottom: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;

            p{
                font-size: 14px;

                strong {
                    margin-right: 5px;
                }
            }

            h3 {
                margin: 0;
                font-size: 42px;
                color: var(--c-primary);
            }
        }
    }
    &__addpass {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            &:first-child {
                margin-bottom: 20px;
            }
        }
    }
}
