.content {
    padding: 30px 15px;
    background-color: #ffffff;
    width: 100%;
    overflow-x: scroll;

    @media screen and (min-width: 991px) {
        padding: 30px;
    }

    @media screen and (min-width: 1201px) {
        padding: 50px;
        height: 100vh;
        width: 70%;
    }

    @media screen and (min-width: 1920px) {
        width: 75%;
        padding: 80px;
    }

    &__back {
        color: var(--c-primary);
        text-decoration: none;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        opacity: .6;

        svg {
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }

        &:hover {
            opacity: 1;
        }
    }

    &__header {

        h2 {
            color: var(--c-primary);
            font-size: 24px;
            margin-bottom: 15px;
            margin-top: 0;
            text-align: center;

            @media screen and (min-width: 991px) {
                text-align: left;
                font-size: 32px;
                margin-bottom: 30px;
            }
        }

        &-banner {
            background-color: #f7f7f7;
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            margin-bottom: 15px;

            @media screen and (min-width: 768px) {
                padding: 30px;
            }

            @media screen and (min-width: 991px) {
                flex-direction: row;
            }

            &-saldo {
                display: flex;

                img {
                    margin-right: 0;
                    width: 70px;
                    height: 100%;

                    @media screen and (min-width: 991px) {
                        margin-right: 30px;
                        width: 117px;
                    }
                }

                ul {
                    margin: 0 10px;

                    @media screen and (min-width: 991px) {
                        margin: 0 50px;
                    }

                    li {
                        font-size: 20px;
                        font-weight: 700;
                        color: var(--c-primary);

                        @media screen and (min-width: 991px) {
                            font-size: 32px;
                        }
                    }

                    .label {
                        font-weight: 700;
                        font-size: 16px;
                        color: black;
                        margin-bottom: 5px;
                    }
                }
            }

            &-download {
                margin-top: 15px;

                @media screen and (min-width: 991px) {
                    margin-top: initial;
                }
            }
        }
    }

    &__table {
        min-width: 500px;

        @media screen and (max-width: 530px) {
            overflow-x: scroll;
        }

        table {
            background-color: #F7F7F7;
            padding: 15px;
            font-size: 14px;
            white-space: nowrap;

            @media screen and (min-width: 991px) {
                font-size: 16px;
            }

            thead {
                text-align: left;
            }

            td {
                font-weight: 300;
                height: 30px;

                @media screen and (min-width: 991px) {
                    height: 50px;
                }

                &.text-red {
                    font-weight: 500;
                    color: #FD054A;
                }

                &.text-green {
                    font-weight: 500;
                    color: green;
                }
            }
        }
    }

    &__text {
        padding: 30px 0;

        h2 {
            color: var(--c-primary);
            font-size: 24px;
            margin-bottom: 15px;
            margin-top: 0;
            text-align: center;

            @media screen and (min-width: 991px) {
                text-align: left;
                font-size: 32px;
                margin-bottom: 30px;
            }
        }


        p {
            font-size: 14px;
            text-align: center;

            @media screen and (min-width: 991px) {
                font-size: 16px;
                text-align: left;
            }
        }
    }

    &__alert {
        background-color: #FFF7F9;
        border: 1px solid #FFDEE7;
        text-align: center;
        padding: 15px 15px 19px 15px;

        @media screen and (min-width: 991px) {
            border-radius: 30px;
        }

        p {
            line-height: 14px;

            strong {
                color: var(--c-primary);

                @media screen and (min-width: 991px) {
                    font-size: 14px;
                }
            }
        }
    }

    &__rewards {
        padding: 30px 0 20px 0;

        @media screen and (min-width: 500px) {
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 50px;
        }

        @media screen and (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
            column-gap: 30px;
            row-gap: 60px;
            padding: 90px 0 50px 0;
        }

        @media screen and (min-width: 1650px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media screen and (min-width: 2400px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        &-item {
            //background-color: #F7F7F7;
            background-color: #194090;
            border-radius: 20px;
            display: flex;
            align-items: center;
            position: relative;
            flex-direction: column;
            text-align: center;
            padding: 40px 30px 30px 30px;
            margin-bottom: 30px;

            @media screen and (min-width: 500px) {
                margin-bottom: initial;
            }

            @media screen and (min-width: 768px) {
                padding: 60px 40px 40px 40px;
            }

            img {
                position: absolute;
                //top: -15px;
                //width: 70px;

                top: -72px;
                width: 185px;

                @media screen and (min-width: 991px) {
                    //width: 117px;
                    //top: -35px;

                    width: 220px;
                    top: -80px;
                }
            }

            p {
                color: #FFF;
                font-size: 14px;

                @media screen and (min-width: 991px) {
                    font-size: 16px;
                }

                strong {
                    font-size: 32px;
                    font-weight: 700;
                    //color: var(--c-primary);
                    color: #FFF;
                }
            }

            a {
                margin-top: 20px;
            }
        }

        .opacity {
            opacity: .2;
            pointer-events: none;
        }
    }
}

.password-block {
    margin-top: 30px;
    margin-bottom: 30px;

    .row {
        padding-top: 30px;
    }
}
